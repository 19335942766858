.watchlist-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.watchlist-content {
  padding: 15px;
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.watchlist-content-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  .title {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .edit-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .title-field {
        flex: 1;
      }
    }
  }

  .subtitle {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}

.watchlist {
  height: calc(100vh - 150px);
  overflow: hidden;
  margin-left: -15px;
  padding-right: 0 !important;

  .watchlist-card {
    .label {
      font-weight: bold;
      margin-right: 5px;
    }

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .watchlist-list {
    box-shadow: 1px -4px 5px rgba(0, 0, 0, 0.3);
    height: 100%;
    position: fixed;
    top: 50px;
    bottom: 30px;
    width: 15%;
  }

  .selected {
    background-color: #0c243c12;
  }
}

.sidebar-list.watchlist {
  width: 12%;
}

.watchlist-stocks {
  width: 200px;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.3);
}
