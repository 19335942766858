.playbook-header {
  .right-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.playbook {
  padding: 0;
  padding-bottom: 0;
  margin-left: -15px;

  .selected {
    background-color: #0c243c12;
  }
}

.playbook-form {
  .MuiDialog-paper {
    position: inherit !important;
  }
}
