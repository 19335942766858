.page:has(.reports-page) .page-content {
  flex-direction: column !important;
}

.report-tab {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 400px auto;
  gap: 10px;
}

.full-width .area-chart {
  grid-column: span 2;
}
