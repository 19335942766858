.red {
  color: #960018;
}

.green {
  color: #228b22;
}

.react-datepicker-wrapper,
.datepicker,
.timepicker {
  width: 100%;
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ql-container.ql-snow {
  height: 100%;
}

.ql-editor {
  height: 100%;
  overflow-y: scroll;
}

.quill {
  height: 100%;
}
