.checkbox-label {
  margin-left: 0 !important;
}

.setup-card {
  .card-header {
    justify-content: space-between;
  }
}

.setup-form {
  .MuiDialog-paper {
    position: inherit !important;
  }
}
