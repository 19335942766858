.gapper-card-header {
  padding: 5px;
}

.gapper-content {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 15px;

  .circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    background-color: rgba(128, 128, 128, 0.471);
    cursor: default;
  }

  .circle.green {
    background-color: green;
    color: white;
  }

  .summary {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
