.tickers-header {
  .right-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.tickers {
  padding: 0;
  padding-bottom: 0 !important;
  height: calc(100vh - 150px);
  overflow: hidden;

  .main-content {
    padding: 15px;

    .subtitle {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}

.tickers-list {
  width: 250px;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.3);
}

.selected {
  background-color: #0c243c12 !important;
}
