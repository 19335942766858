@import '../../styles/variables.scss';

.sidebar {
  background-color: $primary-color;
  position: fixed;
  height: 100%;
  margin-top: 50px;
  width: 210px;
  z-index: 1199;
  transform: translateX(-76%);
  color: white;

  .MuiListItem-root:hover {
    transform: translateX(75%);
  }

  .MuiListItem-root {
    z-index: 99999;
    background-color: $primary-color;
    transition: transform 250ms ease-in-out;
    position: relative;
  }

  .MuiButtonBase-root {
    z-index: 999;
    position: relative;
  }

  .MuiListItemIcon-root {
    color: white;
    position: relative;
    z-index: 999;
  }

  .MuiListItemIcon-root {
    min-width: 25px;
  }

  .active {
    background-color: $primary-color-dark;
  }

  .active::after {
    content: '';
    content: '';
    position: absolute;
    top: 50%;
    left: 96%;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid white;
    transform: translateY(-50%);
  }
}
