.container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 50px auto;
  grid-template-areas:
    'header header'
    'sidebar page'
    'footer footer';
  height: 100vh;
  background-color: #f6f6f6;
}

.header {
  grid-area: header;
  background-color: #224660;
  color: white;
  grid-area: header;
  width: 100%;
}

.sidebar {
  grid-area: sidebar;
}

.page {
  grid-area: page;
  overflow: auto;
  height: 100%;

  .page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    padding: 10px;
    width: calc(100% - 70px);
    height: 30px;
    margin-bottom: 15px;
    z-index: 1000;
    border-radius: 0;
  }

  .page-content {
    margin-top: 50px;
    padding: 15px;
    display: flex;
    flex-direction: row;
  }
}

.footer {
  grid-area: footer;
  height: 30px;
  background-color: #224660;
  color: white;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
}

.sidebar-list:not(.collapsed) {
  width: 17%;
}

.sidebar-list {
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: -15px !important;
  transition: width 400ms ease;
}

.sidebar-list.collapsed {
  width: 40px;
  background-color: #eaecec;

  .text {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 4px;
}

.upload-dialog {
  .dialog-content {
    padding-top: 10px !important;
  }

  #form-file-upload {
    height: 8rem;
    width: 25rem;
    max-width: 100%;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
    margin-top: 10px;
  }

  #label-file-upload.drag-active {
    background-color: #c4c4c4;
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}
