.trade-calendar {
  .day.positive {
    background-color: rgba(41, 171, 135, 0.6);
  }

  .day.negative {
    background-color: rgba(234, 60, 83, 0.6);
  }

  .pnl,
  .month-pnl {
    &.positive {
      color: rgba(41, 171, 135, 0.6);
    }
  }

  .pnl,
  .month-pnl {
    &.negative {
      color: rgba(234, 60, 83, 1);
    }
  }

  .pnl {
    font-size: 13px;
    font-weight: 500;
  }

  .trade-count {
    font-size: 11px;
  }
}
