.page-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.trades {
  .MuiInputBase-root {
    margin-top: 12px !important;
  }
}
