.weekly-pnl {
  .title {
    font-weight: 500;
    font-size: 18px;
    color: #666666;
  }

  .subtitle {
    font-size: 14px;
    color: rgb(83, 83, 83);
  }

  .pnl,
  .rate {
    font-size: 20px;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.win-percent {
  .title {
    font-weight: 500;
    font-size: 14px;
    color: #666666;

    .value {
      color: black;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.avg-win-loss {
  .title {
    font-weight: 500;
    font-size: 16px;
    color: #666666;

    .value {
      color: black;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .bar-container {
    width: 100%;
    height: 30px;
    display: flex;
    overflow: hidden;
  }

  .bar {
    height: 100%;
    transition: width 1s ease-out;
  }

  .red {
    background-color: rgba(234, 60, 83, 0.9);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 0%;
  }

  .red.round-corners,
  .green.round-corners {
    border-radius: 10px;
  }

  .green {
    background-color: rgba(41, 171, 135, 0.92);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 0%;
  }

  .bar-labels {
    font-size: 14px;
    padding: 3px 3px;
    display: flex;
    justify-content: space-between;
  }
}
