.profile {
  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.profile-form {
  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0;
  }
}
