.header {
  .MuiFormControl-root {
    margin: 0;

    label,
    .MuiInput-root,
    .MuiSvgIcon-root {
      color: white;
    }
  }
}
