.gappers-header {
  .right-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.gappers {
  height: calc(100vh - 150px);
  overflow: hidden;
  padding-top: 0 !important;

  .label {
    font-weight: bold;
  }
}
