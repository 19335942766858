.notebook-header {
  .right-panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.notebook {
  padding: 0;
  padding-bottom: 0 !important;
  margin-left: -15px;
  height: calc(100vh - 150px);
  overflow: hidden;

  .selected {
    background-color: #0c243c12;
  }

  .main-content {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
  }

  .notebook-page-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .title {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .edit-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .title-field {
          flex: 1;
        }
      }
    }

    .subtitle {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  .notebook-page-body {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    height: 100%;
  }

  .notebook-page-content {
    padding: 15px;
    height: 95%;
    overflow: auto;
    width: calc(100% - 200px);
    overflow: hidden;
  }
}

.notebook-pages {
  width: 200px;
  box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.3);

  .notebook-pages-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.stock-form .MuiDialog-paper {
  position: inherit !important;
}
